






import { Component, Prop, Emit } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import G6 from '@antv/g6'
import emitter from './Emitter'

@Component({
  name: 'LinkPicture',
})
export default class LinkPicture extends VueBase {
  @Prop() data!: any

  @Emit('openRight')
  private openRight(flag: any, node: any) {
    return [flag, node]
  }
  private GraphHandel: any = null

  private clearfun() {
    const nodes = this.GraphHandel.getNodes()
    const edges = this.GraphHandel.getEdges()
    nodes.forEach((item: any) => {
      if (item.get('model').type === 'my-image') {
        item.clearStates('opacity')
      } else {
        item.clearStates('selected')
        item.clearStates('opacity')
      }
    })
    edges.forEach((item: any) => {
      item.clearStates('opacity')
    })
    this.openRight(false, null)
  }
  private async run(height: number, width: number) {
    const graph = new G6.Graph({
      container: 'mountNode', // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
      defaultEdge: {
        type: 'line-dash',
        loopCfg: {
          dist: 40,
        },
        labelCfg: {
          style: {
            fill: '#959FB4',
            stroke: '#E6E9EC',
          },
        },
      },
      modes: {
        default: [
          'drag-canvas',
          'zoom-canvas',
          {
            type: 'tooltip',
            formatText(model: any) {
              switch (model.type) {
                case 'inner-img':
                  return `Agent: ${model.tooltip}`
                case 'my-image':
                  let modelDom = ''
                  let mysqlDom = `<div style="background: #f2f3f5;padding:8px;margin-top:16px;"><span style="color: #acb4c4;">端口：</span><span style="color: #38435a;">${model.port}</span></div>
                  <div style="background: #f2f3f5;padding:8px;"><span style="color: #acb4c4;">地址：</span><span  style="color: #1a80f2;">${model.address}</span></div>`
                  model.meta &&
                    model.meta.forEach((item: any) => {
                      modelDom += `<div style="background: #f2f3f5;padding:8px;margin-top:16px;"><span style="color: #acb4c4;">端口：</span><span style="color: #38435a;">${item.port}</span></div>
                  <div style="background: #f2f3f5;padding:8px;"><span style="color: #acb4c4;">地址：</span><span  style="color: #1a80f2;">${item.address}</span></div>`
                    })

                  return `<div style="width: 300px;padding: 8px;font-size:14px;">
                   <div style="color: #4a72ae;">DB: ${model.service_type}</div> 
                    ${modelDom || mysqlDom}
                  </div> `
                case 'rect':
                  return `项目: ${model.tooltip}`
                default:
                  return ``
              }
            },
            shouldUpdate: (e: any) => true,
          },
        ],
      },
      layout: {
        type: 'gForce',
        animate: false,
        linkDistance: 100,
        preventOverlap: true,
        nodeStrength: function (e: any) {
          return e.type === 'rect' ? 8000 : 4000
        },
        onTick: () => {
          // 可选
          // console.log('ticking')
        },
        onLayoutEnd: () => {
          // 可选
          // console.log('force layout done')
        },
      },
    })

    this.GraphHandel = graph

    graph.on('click', () => {
      emitter.emit('clearLevel')
      this.clearfun()
    })

    const lineDash = [5, 5]
    G6.registerEdge(
      'line-dash',
      {
        drawShape: (cfg: any, group: any) => {
          const startPoint = cfg.startPoint
          const endPoint = cfg.endPoint
          const keyShape = group.addShape('path', {
            attrs: {
              endArrow: {
                path: 'M 0,0 L 6,4 L 6,-4 Z', // 自定义箭头路径
                d: 0, // 偏移量
                lineDash: [10, 0],
                lineWidth: 2,
                stroke: '#959FB4',
                fill: '#959FB4',
              },
              path: [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x, endPoint.y],
              ],
              stroke: '#959FB4',
              lineDash: lineDash,
              lineWidth: 2,
              strokeOpacity: '0.6',
            },
          })
          return keyShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'opacity') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.6)
              })
            }
          }
        },
      },
      'line'
    ) // 该自定义边继承 quadratic

    G6.registerEdge(
      'line-dash-each',
      {
        drawShape: (cfg: any, group: any) => {
          const startPoint = cfg.startPoint
          const endPoint = cfg.endPoint
          const keyShape = group.addShape('path', {
            attrs: {
              endArrow: {
                path: 'M 0,0 L 6,4 L 6,-4 Z', // 自定义箭头路径
                d: 0, // 偏移量
                lineDash: [10, 0],
                lineWidth: 2,
                stroke: '#959FB4',
                fill: '#959FB4',
              },
              path: [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x, endPoint.y],
              ],
              stroke: '#959FB4',
              lineDash: lineDash,
              lineWidth: 2,
              strokeOpacity: '0.6',
            },
          })
          return keyShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'opacity') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.6)
              })
            }
          }
        },
      },
      'quadratic'
    ) // 该自定义边继承 quadratic

    G6.registerEdge(
      'line-dash-loop',
      {
        draw: (cfg: any, group: any) => {
          const startPoint = cfg.startPoint
          const endPoint = cfg.endPoint
          const keyShape = group.addShape('path', {
            attrs: {
              endArrow: {
                path: 'M 0,0 L 6,4 L 6,-4 Z', // 自定义箭头路径
                d: 0, // 偏移量
                lineDash: [10, 0],
                lineWidth: 2,
                stroke: '#959FB4',
                fill: '#959FB4',
              },
              path: [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x, endPoint.y],
              ],
              stroke: '#959FB4',
              lineDash: lineDash,
              lineWidth: 2,
              strokeOpacity: '0.6',
            },
          })
          return keyShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'opacity') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.6)
              })
            }
          }
        },
      },
      'loop'
    )

    G6.registerNode(
      'inner-img',
      {
        afterDraw(cfg: any, group: any) {
          const size: any = cfg.size
          const width: any = size / 2
          const height: any = size / 2
          // 增加一个 path 图形作为 keyShape
          const imageShape = group.addShape('image', {
            attrs: {
              x: -width / 2,
              y: -height / 2,
              width: width,
              height: height,
              img: cfg.img,
              opacity: 1,
              fillOpacity: 1,
            },
            draggable: true,
            name: 'image-Shape',
          })
          return imageShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'opacity') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('opacity', 0.2)
                item.attr('fillOpacity', 0.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('opacity', 1)
                item.attr('fillOpacity', 1)
              })
            }
          }
          if (name === 'selected') {
            if (value) {
              shapes[0].attr('shadowBlur', 20)
            } else {
              shapes[0].attr('shadowBlur', 0)
            }
          }
        },
      },
      'circle'
    )

    G6.registerNode(
      'my-image',
      {
        afterDraw(cfg: any, group: any) {
          const size: any = cfg.size
          const width: any = size / 2
          const height: any = size / 2
          // 增加一个 path 图形作为 keyShape
          const imageShape = group.addShape('image', {
            attrs: {
              x: -width,
              y: -height / 2,
              width: size,
              height: size,
              img: cfg.img,
              opacity: 1,
              fillOpacity: 1,
              style: {
                cursor: 'pointer',
              },
            },
            draggable: true,
            name: 'image-Shape',
          })
          return imageShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'opacity') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('opacity', 0.2)
                item.attr('fillOpacity', 0.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('opacity', 1)
                item.attr('fillOpacity', 1)
              })
            }
          }
        },
      },
      'circle'
    )

    graph.data(this.data) // 读取 Step 2 中的数据源到图上
    graph.render() // 渲染图

    graph.on('node:dragstart', function (e: any) {
      graph.get('layoutController').data.nodes.forEach((node: any) => {
        node.fx = node.x
        node.fy = node.y
      })
    })

    graph.on('node:drag', function (e: any) {
      refreshDragedNodePosition(e)
      graph.layout()
    })

    function refreshDragedNodePosition(e: any) {
      const model = e.item.get('model')
      model.fx = e.x
      model.fy = e.y
      model.x = e.x
      model.y = e.y
    }

    var inI: any = null
    var outI: any = null

    graph.on('node:mouseenter', (evt: any) => {
      const { item } = evt
      const model = item.get('model')
      clearInterval(outI)
      if (model.type === 'my-image') {
        return
      }
      inI = setInterval(() => {
        if (model.type === 'rect') {
          const base = 4
          if (model.size[1] >= 42) {
            clearInterval(inI)
            return
          }
          model.size[0] += 0.5 * base
          model.size[1] += 0.5
        }
        if (model.type === 'inner-img') {
          if (model.size >= 52) {
            clearInterval(inI)
            return
          }
          model.size += 0.5
        }
        graph.updateItem(item, model)
      }, 25)
    })

    graph.on('node:mouseleave', (evt: any) => {
      const { item } = evt
      const model = item.get('model')
      clearInterval(inI)
      if (model.type === 'my-image') {
        return
      }
      outI = setInterval(() => {
        if (model.type === 'rect') {
          const base = 5.125
          if (model.size[1] <= 40) {
            clearInterval(outI)
            return
          }
          model.size[0] -= 0.5 * base
          model.size[1] -= 0.5
        }
        if (model.type === 'inner-img') {
          if (model.size <= 48) {
            clearInterval(outI)
            return
          }
          model.size -= 0.5
        }
        graph.updateItem(item, model)
      }, 25)
    })

    graph.on('node:click', (e: any) => {
      emitter.emit('clearLevel')
      const { item } = e
      const model = item.get('model')
      if (model.type === 'my-image') {
        return
      }
      this.openRight(true, model)
      const hasSelected = item.hasState('selected')
      if (!hasSelected) {
        const nodes = graph.getNodes()
        const edges = graph.getEdges()
        nodes.forEach((item: any) => {
          item.clearStates('selected')
          graph.setItemState(item, 'opacity', true)
        })
        edges.forEach((item: any) => {
          graph.setItemState(item, 'opacity', true)
        })
        graph.setItemState(item, 'selected', true)
        item.clearStates('opacity')
      } else {
        graph.clearItemStates(item, 'selected')
        item.clearStates('opacity')
      }
    })
  }

  mounted() {
    emitter.on('clearfun', () => {
      this.clearfun()
    })
    emitter.on('level', (level) => {
      const nodes = this.GraphHandel.getNodes()
      nodes.forEach((node: any) => {
        this.GraphHandel.setItemState(node, 'selected', false)
        if (node.get('model').level === level) {
          this.GraphHandel.setItemState(node, 'selected', true)
        }
      })
    })
    var clientHeight = document.body.clientHeight
    var clientWidth = document.body.clientWidth
    this.run(clientHeight - 260 < 600 ? 600 : clientHeight - 260, clientWidth)
  }
  destroyed() {
    emitter.off('level', (level) => {
      console.log(level)
    })
    emitter.off('clearfun', (level) => {
      console.log(level)
    })
  }
}
